import { j as o } from "../../jsx-runtime-B6kdoens.js";
import { getBannerIconProps as m } from "./utils.es.js";
import { u as y } from "../../useDisclose-DpzoV9h_.js";
import "react";
import "@floating-ui/react-dom";
import { I as p } from "../../CheckIcon-Dl7Gf-OR.js";
import "../Icon/countryVariants.es.js";
import "../../styles-DaQVIHg4.js";
import { T as x } from "../../Typography-Cc9k4gPj.js";
import { StyledBannerContainer as g, StyledBannerContent as f, StyledBannerCloseIconButton as u } from "./style.es.js";
import { F as c } from "../../FileIcon-C0c-P2i2.js";
import "../FeaturedIcon/constants.es.js";
import { createPortal as j } from "react-dom";
import l from "styled-components";
import "../Theme/themes.es.js";
import "../../palettes-atf5CkC7.js";
import { getSpacings as B, getBreakpointMediaQuery as s, getZIndexes as C, getPaletteBorder as d } from "../Theme/utils.es.js";
import "../../CssBaseline-XoOio97s.js";
import { B as P } from "../../hooks-ITQtCCBg.js";
import { u as an } from "../../hooks-ITQtCCBg.js";
import { useMediaQuery as $ } from "../Theme/hooks.es.js";
import b from "./useBannerProvider.es.js";
const I = { variant: "info-circle" }, S = (e) => {
  const {
    title: i,
    style: r,
    children: n,
    className: a,
    icon: t = I
  } = e, { isOpen: v, onClose: h } = y(!0);
  return v ? /* @__PURE__ */ o.jsxs(
    g,
    {
      style: r,
      className: a,
      $hasChildren: !!n,
      children: [
        typeof t == "object" && t && "variant" in t ? /* @__PURE__ */ o.jsx(
          c,
          {
            size: "sm",
            variant: "light",
            color: "gray",
            iconProps: {
              ...t,
              variant: (t == null ? void 0 : t.variant) || "info-circle"
            }
          }
        ) : t,
        /* @__PURE__ */ o.jsxs(f, { children: [
          /* @__PURE__ */ o.jsx(x, { size: "sm", weight: "semibold", color: "secondary", children: i }),
          n
        ] }),
        /* @__PURE__ */ o.jsx(
          u,
          {
            color: "gray",
            variant: "text",
            onClick: h,
            children: /* @__PURE__ */ o.jsx(p, { variant: "close" })
          }
        )
      ]
    }
  ) : null;
}, on = (e) => {
  const { title: i, variant: r = "neutral", children: n } = e, a = m(r);
  return /* @__PURE__ */ o.jsx(
    S,
    {
      title: i,
      icon: /* @__PURE__ */ o.jsx(
        c,
        {
          size: "sm",
          variant: "light",
          color: a.color,
          iconProps: { variant: a.variant }
        }
      ),
      children: n
    }
  );
}, F = (e) => {
  const {
    variant: i = "neutral",
    icon: r
  } = e;
  if (r && typeof r == "object" && "variant" in r) {
    const n = m(i);
    return /* @__PURE__ */ o.jsx(
      c,
      {
        size: "sm",
        variant: "light",
        color: n.color,
        iconProps: {
          ...r,
          variant: n.variant
        }
      }
    );
  }
  return r;
}, z = l(f)`
  flex-direction: row;
  align-items: center;

  gap: ${B("lg")};

  ${s("max", "lg")} {
    flex-direction: column;
    margin-left: auto;
  }
`, w = l.div`
  text-align: center;
`, k = (e) => {
  const {
    variant: i,
    icon: r,
    title: n,
    children: a
  } = e, t = $("mobile");
  return /* @__PURE__ */ o.jsxs(z, { children: [
    !t && /* @__PURE__ */ o.jsx(
      F,
      {
        variant: i,
        icon: r
      }
    ),
    /* @__PURE__ */ o.jsx(w, { children: /* @__PURE__ */ o.jsx(x, { size: "sm", weight: "semibold", color: "secondary", children: n }) }),
    a
  ] });
}, M = l(g)`
  border: none;
  position: fixed;
  border-radius: 0;
  flex-direction: row;
  justify-content: center;
  z-index: ${C("tooltip")};
  ${s("max", "lg")} {
    bottom: 0;
    border-top: 1px solid ${d("primary")};
  }
  ${s("min", "lg")} {
    top: 0;
    border-bottom: 1px solid ${d("primary")};
  }
`, O = l(u)`
  position: absolute;
  padding: ${B("lg")};
  margin-left: 0;
  ${s("max", "lg")} {
    position: relative;
    padding: 0;
    margin-left: auto;
  }
`, rn = ({ children: e }) => {
  const {
    isOpen: i,
    floatingBannerRef: r,
    bannerDetails: n,
    handleCloseBanner: a,
    handleOpenBanner: t
  } = b();
  return /* @__PURE__ */ o.jsxs(
    P.Provider,
    {
      value: {
        openBanner: t
      },
      children: [
        e,
        i && j(
          /* @__PURE__ */ o.jsxs(
            M,
            {
              id: "floatingBanner",
              ref: r,
              $hasChildren: !!e,
              children: [
                n && /* @__PURE__ */ o.jsx(
                  k,
                  {
                    variant: n == null ? void 0 : n.variant,
                    icon: n == null ? void 0 : n.icon,
                    title: n == null ? void 0 : n.title,
                    children: n == null ? void 0 : n.children
                  }
                ),
                /* @__PURE__ */ o.jsx(
                  O,
                  {
                    color: "gray",
                    variant: "text",
                    onClick: a,
                    children: /* @__PURE__ */ o.jsx(p, { variant: "close" })
                  }
                )
              ]
            }
          ),
          document.body
        )
      ]
    }
  );
};
export {
  rn as BannerProvider,
  S as BaseBanner,
  on as default,
  an as useBanner
};
