import { useRef as u, useState as f, useEffect as g } from "react";
import { u as B } from "../../useDisclose-DpzoV9h_.js";
import "@floating-ui/react-dom";
import "../Theme/themes.es.js";
import "../../palettes-atf5CkC7.js";
import "react-phone-number-input";
import { useMediaQuery as y } from "../Theme/hooks.es.js";
import "../../jsx-runtime-B6kdoens.js";
import "styled-components";
import "../../CssBaseline-XoOio97s.js";
import { getBannerIconProps as v } from "./utils.es.js";
const w = () => {
  const a = u(null), { isOpen: o, onOpen: s, onClose: d } = B(!1), i = y("mobile"), [e, c] = f(void 0), t = () => {
    document.body.style.padding = "0";
  }, l = e != null && e.variant ? v(e.variant).variant : "info-circle", m = () => {
    t(), d();
  }, p = (n) => {
    c({
      ...n,
      icon: n.icon ?? { variant: l }
    }), s();
  };
  return g(() => {
    var n;
    if (o) {
      const r = (n = document.getElementById("floatingBanner")) == null ? void 0 : n.getBoundingClientRect().height;
      t(), i ? document.body.style.paddingBottom = `${r}px` : document.body.style.paddingTop = `${r}px`;
    }
    return () => {
      t();
    };
  }, [o, i]), {
    floatingBannerRef: a,
    isOpen: o,
    bannerDetails: e,
    handleCloseBanner: m,
    handleOpenBanner: p
  };
};
export {
  w as default
};
